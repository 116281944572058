import { MenuItemProps } from '@/components/atoms/SelectField'
import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import { withLayout } from '@/components/template/Layout'
import MonthsData from '@/components/template/Months/MonthsData'
import { siteApi } from '@/ghgApi'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const Months = () => {
    const { expandedMenu } = useStore()
    const useStyle = makeStyles({
        main: {
            marginBottom: 50,
            position: 'relative',
            maxWidth: theme.contentContainer.width,
        },
        header: {
            position: 'fixed',
            zIndex: 1,
            top: 73,
            width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
            transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
        selectGroup: {},
        content: {
            paddingTop: 64,
        },
    })
    const classes = useStyle()
    const {
        storeState,
        analysisState,
        setAnalysisState,
        setStoreState,
    } = useStore()
    const [siteOptions, setSiteOptions] = useState<MenuItemProps[]>([])

    useEffect(() => {
        fetchOrganizationSites()
    }, [])

    const fetchOrganizationSites = async () => {
        await siteApi.listSite(undefined, undefined, 1000)
            .then((res) => {
                if (!res.data.data) {
                    console.log('siteApi.listSite response empty-sites.')
                    return
                }
                const sites = res.data.data
                const options = sites.map((s) => ({ id: s.id, value: s.name })) || []
                setSiteOptions(options)
                setStoreState({
                    ...storeState,
                    sites: sites,
                })
                setAnalysisState({
                    ...analysisState,
                    sites: sites,
                })
            })
            .catch((err) => console.log(err))
    }

    return (
        <main className={classes.main}>
            <title>データ入力｜Emission View</title>
            <div>
                <div className={classes.header}>
                    <Space />
                    <div className={classes.selectGroup}>
                        <SelectorGroup options={siteOptions}/>
                    </div>
                    <Space height={15} />
                </div>
            </div>
            <div style={{ maxWidth: theme.contentContainer.width }} className={classes.content}>
                <MonthsData />
            </div>
        </main>
    )
}
export default withLayout(Months)
